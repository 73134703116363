import { Navigate, RouteObject } from 'react-router-dom';

export const ProviderProfileRoutes: RouteObject = {
  path: 'profile',
  lazy: async () => {
    const { ProfileLayout } = await import('./layout');
    return { element: <ProfileLayout /> };
  },
  children: [
    {
      index: true,
      element: <Navigate to="summary" replace />,
    },
    {
      path: 'summary',
      lazy: async () => {
        const { SummaryPage } = await import('./summary/page');
        return { element: <SummaryPage /> };
      },
    },
    {
      path: 'personal-info',
      lazy: async () => {
        const { PersonalInfoPage } = await import('./personal-info/page');
        return { element: <PersonalInfoPage /> };
      },
    },
    {
      path: 'professional-ids',
      lazy: async () => {
        const { ProfessionalIDsPage } = await import('./professional-ids/page');
        return { element: <ProfessionalIDsPage /> };
      },
    },
    {
      path: 'education',
      lazy: async () => {
        const { EducationPage } = await import('./education/page');
        return { element: <EducationPage /> };
      },
    },
    {
      path: 'liability-insurance',
      lazy: async () => {
        const { LiabilityInsurancePage } = await import(
          './liability-insurance/page'
        );
        return { element: <LiabilityInsurancePage /> };
      },
    },
    {
      path: 'specialties',
      lazy: async () => {
        const { SpecialtiesPage } = await import('./specialties/page');
        return { element: <SpecialtiesPage /> };
      },
    },
    {
      path: 'employment',
      lazy: async () => {
        const { EmploymentPage } = await import('./employment/page');
        return { element: <EmploymentPage /> };
      },
    },
    {
      path: 'documents',
      lazy: async () => {
        const { DocumentsPage } = await import('./documents/page');
        return { element: <DocumentsPage /> };
      },
    },
    {
      path: 'disclosure',
      lazy: async () => {
        const { DisclosurePage } = await import('./disclosure/page');
        return { element: <DisclosurePage /> };
      },
    },
    {
      path: 'external-accounts',
      lazy: async () => {
        const { ExternalAccountsPage } = await import(
          './external-accounts/page'
        );
        return { element: <ExternalAccountsPage /> };
      },
    },
  ],
};
