import { Navigate, RouteObject } from 'react-router-dom';

export const TaxEntityByIdRoutes: RouteObject = {
  path: 'tax-entities/profiles/:taxEntityId',
  lazy: async () => {
    const { TaxEntityProfileLayout } = await import('./layout');
    return { element: <TaxEntityProfileLayout /> };
  },
  children: [
    {
      index: true,
      element: <Navigate to="details" replace />,
    },
    {
      path: 'details',
      lazy: async () => {
        const { ProfileDetailsLayout } = await import('./details/layout');
        return { element: <ProfileDetailsLayout /> };
      },
      children: [
        {
          index: true,
          element: <Navigate to="summary" replace />,
        },
        {
          path: 'summary',
          lazy: async () => {
            const { SummaryPage } = await import('./details/summary/page');
            return { element: <SummaryPage /> };
          },
        },
        {
          path: 'entity-info',
          lazy: async () => {
            const { EntityInfoPage } = await import(
              './details/entity-info/page'
            );
            return { element: <EntityInfoPage /> };
          },
        },
        {
          path: 'address',
          lazy: async () => {
            const { AddressPage } = await import('./details/address/page');
            return { element: <AddressPage /> };
          },
        },
        {
          path: 'financial-info',
          lazy: async () => {
            const { FinancialInfoPage } = await import(
              './details/financial-info/page'
            );
            return { element: <FinancialInfoPage /> };
          },
        },
        {
          path: 'operational-info',
          lazy: async () => {
            const { OperationalInfoPage } = await import(
              './details/operational-info/page'
            );
            return { element: <OperationalInfoPage /> };
          },
        },
        {
          path: 'documents',
          lazy: async () => {
            const { DocumentsPage } = await import('./details/documents/page');
            return { element: <DocumentsPage /> };
          },
        },
      ],
    },
    {
      path: 'practice-locations',
      lazy: async () => {
        const { PracticeLocationsPage } = await import(
          './practice-locations/page'
        );
        return { element: <PracticeLocationsPage /> };
      },
    },
    {
      path: 'providers',
      lazy: async () => {
        const { ProvidersPage } = await import('./providers/page');
        return { element: <ProvidersPage /> };
      },
    },
  ],
};
