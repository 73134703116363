import { Navigate, RouteObject } from 'react-router-dom';

import ProtectedRoute from '@/components/auth/protected-route';

import { UserType } from '@/lib/user/type';

export const OnboardingRoutes: RouteObject[] = [
  {
    path: 'onboarding/admin',
    lazy: async () => {
      const { AdminOnboardingLayout } = await import('./admin/layout');
      return {
        element: (
          <ProtectedRoute userType={UserType.Admin}>
            <AdminOnboardingLayout />
          </ProtectedRoute>
        ),
      };
    },
    children: [
      {
        index: true,
        lazy: async () => {
          const { AdminOnboardingPage } = await import('./admin/page');
          return { element: <AdminOnboardingPage /> };
        },
      },
      {
        path: 'account-details',
        lazy: async () => {
          const { AccountDetailsPage } = await import(
            './admin/account-details/page'
          );
          return { element: <AccountDetailsPage /> };
        },
      },
      {
        path: 'tax-entities',
        lazy: async () => {
          const { TaxEntitiesPage } = await import('./admin/tax-entities/page');
          return { element: <TaxEntitiesPage /> };
        },
      },
      {
        path: 'tax-entities/new',
        lazy: async () => {
          const { OnboardingNewTaxEntitiesPage } = await import(
            './admin/tax-entities/new/page'
          );
          return {
            element: <OnboardingNewTaxEntitiesPage />,
          };
        },
      },
      {
        path: 'tax-entities/:taxEntityId',
        lazy: async () => {
          const { OnboardingTaxEntitiesLayout } = await import(
            './admin/tax-entities/$taxEntityId/layout'
          );
          return {
            element: <OnboardingTaxEntitiesLayout />,
          };
        },
        children: [
          {
            index: true,
            element: <Navigate to="entity-info" replace />,
          },
          {
            path: 'entity-info',
            lazy: async () => {
              const { EntityInfoPage } = await import(
                './admin/tax-entities/$taxEntityId/entity-info/page'
              );
              return { element: <EntityInfoPage /> };
            },
          },
          {
            path: 'address',
            lazy: async () => {
              const { AddressPage } = await import(
                './admin/tax-entities/$taxEntityId/address/page'
              );
              return { element: <AddressPage /> };
            },
          },
          {
            path: 'financial-info',
            lazy: async () => {
              const { FinancialInfoPage } = await import(
                './admin/tax-entities/$taxEntityId/financial-info/page'
              );
              return { element: <FinancialInfoPage /> };
            },
          },
          {
            path: 'operational-info',
            lazy: async () => {
              const { OperationalInfoPage } = await import(
                './admin/tax-entities/$taxEntityId/operational-info/page'
              );
              return { element: <OperationalInfoPage /> };
            },
          },
          {
            path: 'documents',
            lazy: async () => {
              const { DocumentsPage } = await import(
                './admin/tax-entities/$taxEntityId/documents/page'
              );
              return { element: <DocumentsPage /> };
            },
          },
          {
            path: 'practice-locations',
            lazy: async () => {
              const { PracticeLocationsPage } = await import(
                './admin/tax-entities/$taxEntityId/practice-locations/page'
              );
              return { element: <PracticeLocationsPage /> };
            },
          },
        ],
      },
      {
        path: 'invite-providers',
        lazy: async () => {
          const { InviteProvidersPage } = await import(
            './admin/invite-providers/page'
          );
          return { element: <InviteProvidersPage /> };
        },
      },
    ],
  },
];
