import { RouteObject } from 'react-router-dom';

import { ProviderDetailsRoutes } from './$userId/routes';

export const AdminProvidersRoutes: RouteObject = {
  path: 'providers',
  children: [
    {
      index: true,
      lazy: async () => {
        const { ProviderListPage } = await import('./page');
        return { element: <ProviderListPage /> };
      },
    },
    ProviderDetailsRoutes,
    {
      path: 'import',
      lazy: async () => {
        const { ProvidersImportPage } = await import('./import/page');
        return { element: <ProvidersImportPage /> };
      },
    },
    {
      path: 'import/:requestId',
      lazy: async () => {
        const { ProviderImportDetailsPage } = await import(
          './import/$requestId/page'
        );
        return { element: <ProviderImportDetailsPage /> };
      },
    },
  ],
};
