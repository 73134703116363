import { Navigate, RouteObject } from 'react-router-dom';

import { ProviderProfileRoutes } from './profile/routes';

export const ProviderDetailsRoutes: RouteObject = {
  path: ':userId',
  lazy: async () => {
    const { ProviderDetailsLayout } = await import('./layout');
    return { element: <ProviderDetailsLayout /> };
  },
  children: [
    {
      index: true,
      element: <Navigate to="overview" replace />,
    },
    {
      path: 'overview',
      lazy: async () => {
        const { OverviewPage } = await import('./overview/page');
        return { element: <OverviewPage /> };
      },
    },
    {
      path: 'tasks',
      lazy: async () => {
        const { TasksPage } = await import('./tasks/page');
        return { element: <TasksPage /> };
      },
    },
    ProviderProfileRoutes,
    {
      path: 'monitoring',
      lazy: async () => {
        const { OngoingMonitoringPage } = await import(
          './ongoing-monitoring/page'
        );
        return { element: <OngoingMonitoringPage /> };
      },
    },
    {
      path: 'practice-locations',
      lazy: async () => {
        const { PracticeLocationsPage } = await import(
          './practice-locations/page'
        );
        return { element: <PracticeLocationsPage /> };
      },
    },
  ],
};
