import { $api } from '@/lib/api/client';

// TODO: Consider setting a stale time for the query
export function useGetUserDetails({ userId }: { userId?: string | null }) {
  return $api.useQuery(
    'get',
    '/api/v1/users/user-details/{id}/',
    {
      params: {
        path: {
          id: `${userId}`,
        },
      },
    },
    {
      enabled: !!userId,
    },
  );
}
