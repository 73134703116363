import { Navigate, RouteObject } from 'react-router-dom';

export const CredentialingPacketRoutes: RouteObject = {
  path: 'credentialing/:requestId',
  lazy: async () => {
    const { CredentialingPacketLayout } = await import('./layout');
    return { element: <CredentialingPacketLayout /> };
  },
  children: [
    {
      index: true,
      element: <Navigate to="packet-overview" replace />,
    },
    {
      path: 'packet-overview',
      lazy: async () => {
        const { PacketOverview } = await import('./packet-overview/page');
        return { element: <PacketOverview /> };
      },
    },
    {
      path: 'attestation',
      lazy: async () => {
        const { Attestation } = await import('./attestation/page');
        return { element: <Attestation /> };
      },
    },
    {
      path: 'npi',
      lazy: async () => {
        const { NPI } = await import('./npi/page');
        return { element: <NPI /> };
      },
    },
    {
      path: 'dea',
      lazy: async () => {
        const { DEA } = await import('./dea/page');
        return { element: <DEA /> };
      },
    },
    {
      path: 'cds',
      lazy: async () => {
        const { CDS } = await import('./cds/page');
        return { element: <CDS /> };
      },
    },
    {
      path: 'state-licenses',
      lazy: async () => {
        const { StateLicenses } = await import('./state-licenses/page');
        return { element: <StateLicenses /> };
      },
    },
    {
      path: 'board-certifications',
      lazy: async () => {
        const { BoardCertifications } = await import(
          './board-certifications/page'
        );
        return { element: <BoardCertifications /> };
      },
    },
    {
      path: 'education',
      lazy: async () => {
        const { Education } = await import('./education/page');
        return { element: <Education /> };
      },
    },
    {
      path: 'liability-insurance',
      lazy: async () => {
        const { LiabilityInsurance } = await import(
          './liability-insurance/page'
        );
        return { element: <LiabilityInsurance /> };
      },
    },
    {
      path: 'work-history',
      lazy: async () => {
        const { WorkHistory } = await import('./work-history/page');
        return { element: <WorkHistory /> };
      },
    },
    {
      path: 'state-exclusions',
      lazy: async () => {
        const { StateExclusions } = await import('./state-exclusions/page');
        return { element: <StateExclusions /> };
      },
    },
    {
      path: 'sam',
      lazy: async () => {
        const { SAM } = await import('./sam/page');
        return { element: <SAM /> };
      },
    },
    {
      path: 'oig',
      lazy: async () => {
        const { OGI } = await import('./oig/page');
        return { element: <OGI /> };
      },
    },
    {
      path: 'medicare-opt-out',
      lazy: async () => {
        const { MedicareOptOut } = await import('./medicare-opt-out/page');
        return { element: <MedicareOptOut /> };
      },
    },
    {
      path: 'npdb',
      lazy: async () => {
        const { NPDB } = await import('./npdb/page');
        return { element: <NPDB /> };
      },
    },
    {
      path: 'disclosure',
      lazy: async () => {
        const { Disclosure } = await import('./disclosure/page');
        return { element: <Disclosure /> };
      },
    },
    {
      path: 'ofac',
      lazy: async () => {
        const { OFAC } = await import('./ofac/page');
        return { element: <OFAC /> };
      },
    },
    {
      path: 'ssa-dmf',
      lazy: async () => {
        const { SsaDmf } = await import('./ssa-dmf/page');
        return { element: <SsaDmf /> };
      },
    },
  ],
};
