import { Navigate, RouteObject } from 'react-router-dom';

export const MonitoringPacketRoutes: RouteObject = {
  path: 'monitoring/:requestId',
  lazy: async () => {
    const { MonitoringPacketLayout } = await import('./layout');
    return { element: <MonitoringPacketLayout /> };
  },
  children: [
    {
      index: true,
      element: <Navigate to="overview" replace />,
    },
    {
      path: 'overview',
      lazy: async () => {
        const { Overview } = await import('./overview/page');
        return { element: <Overview /> };
      },
    },
    {
      path: 'npi',
      lazy: async () => {
        const { NPI } = await import('./npi/page');
        return { element: <NPI /> };
      },
    },
    {
      path: 'dea',
      lazy: async () => {
        const { DEA } = await import('./dea/page');
        return { element: <DEA /> };
      },
    },
    {
      path: 'cds',
      lazy: async () => {
        const { CDS } = await import('./cds/page');
        return { element: <CDS /> };
      },
    },
    {
      path: 'state-licenses',
      lazy: async () => {
        const { StateLicenses } = await import('./state-licenses/page');
        return { element: <StateLicenses /> };
      },
    },
    {
      path: 'state-exclusions',
      lazy: async () => {
        const { StateExclusions } = await import('./state-exclusions/page');
        return { element: <StateExclusions /> };
      },
    },
    {
      path: 'sam',
      lazy: async () => {
        const { SAM } = await import('./sam/page');
        return { element: <SAM /> };
      },
    },
    {
      path: 'oig',
      lazy: async () => {
        const { OGI } = await import('./oig/page');
        return { element: <OGI /> };
      },
    },
    {
      path: 'medicare-opt-out',
      lazy: async () => {
        const { MedicareOptOut } = await import('./medicare-opt-out/page');
        return { element: <MedicareOptOut /> };
      },
    },
    {
      path: 'npdb',
      lazy: async () => {
        const { NPDB } = await import('./npdb/page');
        return { element: <NPDB /> };
      },
    },
    {
      path: 'ofac',
      lazy: async () => {
        const { OFAC } = await import('./ofac/page');
        return { element: <OFAC /> };
      },
    },
    {
      path: 'ssa-dmf',
      lazy: async () => {
        const { SsaDmf } = await import('./ssa-dmf/page');
        return { element: <SsaDmf /> };
      },
    },
  ],
};
