// If a module fails to load, it may mean the user's browser is using an old
// version of the build. That means user's browser would have an outdated
// URL to the lazy module.
function isModuleNotFoundError(error: Error): boolean {
  // Firefox based browsers
  if (/error loading dynamically imported module/.test(error.message)) {
    return true;
  }

  // Chromium based browsers
  if (/Failed to fetch dynamically imported module/.test(error.message)) {
    return true;
  }

  // Safari based browsers
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  if (
    isSafari &&
    /'text\/html' is not a valid JavaScript MIME type./.test(error.message)
  ) {
    return true;
  }

  return false;
}

// This function is used to detect if the user's browser is using an old
// version of the build. If it is, the page will be reloaded to use the
// latest version of the build. This based on the router throwing an error
// when a module fails to load.
export default function detectVersionChange(error: unknown): boolean {
  if (!(error instanceof Error)) {
    return false;
  }

  if (!isModuleNotFoundError(error)) {
    return false;
  }

  console.error('Detected possible version skew');

  if (typeof window !== 'undefined' && typeof sessionStorage !== 'undefined') {
    // Only reload the page if the error message has not been seen before
    const storageKey = `assured_web_reload:${error.message}`;
    if (!sessionStorage.getItem(storageKey)) {
      sessionStorage.setItem(storageKey, '1');
      window.location.reload();
      console.error('Forcing page reload for possible version skew');
    } else {
      console.error('Ignoring version skew due to multiple reloads');
    }
  }

  return true;
}
