import { Navigate, RouteObject } from 'react-router-dom';

import { PracticeLocationByIdRoutes } from './practice-locations/$practiceLocationId/routes';
import { TaxEntityByIdRoutes } from './profiles/$taxEntityId/routes';

export const TaxEntitiesRoutes: RouteObject[] = [
  {
    path: 'tax-entities',
    lazy: async () => {
      const { TaxEntitiesLayout } = await import('./layout');
      return { element: <TaxEntitiesLayout /> };
    },
    children: [
      {
        index: true,
        element: <Navigate to="profiles" replace />,
      },
      {
        path: 'profiles',
        lazy: async () => {
          const { ProfilesPage } = await import('./profiles/page');
          return { element: <ProfilesPage /> };
        },
      },

      {
        path: 'practice-locations',
        lazy: async () => {
          const { PracticeLocationsPage } = await import(
            './practice-locations/page'
          );
          return { element: <PracticeLocationsPage /> };
        },
      },
    ],
  },
  TaxEntityByIdRoutes,
  PracticeLocationByIdRoutes,
];
