export type ErrorDetail = {
  code: string;
  detail: string;
  attr: string | null;
};

export type ErrorModel = {
  title: string;
  statusCode: number;
  detail: string;
  errors: ErrorDetail[];
};

const UnknownErrorResponse: ErrorModel = {
  title: 'Failed to parse error response',
  statusCode: 520,
  detail: 'Unexpected error response from the server',
  errors: [],
};

/**
 * Represents an HTTP error.
 */
export class HTTPError extends Error {
  /**
   * The response object containing error details.
   */
  public response: ErrorModel;

  /**
   * Creates a new instance of the HTTPError class.
   * @param error - The error object or message.
   * @param statusCode - The HTTP status code.
   */
  constructor(error: unknown) {
    const res = parseErrorObject(error);
    super(res.title);
    this.response = res;
    this.name = 'HTTPError';
  }
}

/**
 * Parses the error object and returns a standardized ErrorModel.
 * @param data - The error object to parse.
 * @returns The standardized ErrorModel.
 */
function parseErrorObject(data: unknown): ErrorModel {
  if (data && typeof data === 'object') {
    return {
      title:
        'title' in data && typeof data.title === 'string'
          ? data.title
          : UnknownErrorResponse.title,
      statusCode:
        'status_code' in data && typeof data.status_code === 'number'
          ? data.status_code
          : UnknownErrorResponse.statusCode,
      detail:
        'detail' in data && typeof data.detail === 'string'
          ? data.detail
          : UnknownErrorResponse.detail,
      errors: 'errors' in data ? parseErrorDetails(data.errors) : [],
    };
  }
  return UnknownErrorResponse;
}

/**
 * Parses the error details and returns an array of ErrorDetail objects.
 * @param errors - The error details to parse.
 * @returns An array of ErrorDetail objects.
 */
function parseErrorDetails(errors: unknown): ErrorDetail[] {
  if (errors && Array.isArray(errors)) {
    return errors.map((error) => {
      if (error && typeof error === 'object') {
        return {
          code:
            'code' in error && typeof error.code === 'string'
              ? error.code
              : 'UNKNOWN',
          detail:
            'detail' in error && typeof error.detail === 'string'
              ? error.detail
              : 'Unknown error',
          attr:
            'attr' in error && typeof error.attr === 'string'
              ? error.attr
              : null,
        };
      }

      return {
        code: 'UNKNOWN',
        detail: 'Unknown error',
        attr: null,
      };
    });
  }

  return [];
}

/**
 * Handles API errors and returns a standardized ErrorModel.
 * @param error - The error object to handle.
 * @returns The standardized ErrorModel.
 */
export async function handleAPIError(error: unknown): Promise<ErrorModel> {
  if (error instanceof HTTPError) {
    return error.response;
  }

  return UnknownErrorResponse;
}

export function isHttpError(error: unknown): error is HTTPError {
  return error instanceof HTTPError;
}
