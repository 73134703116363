import { RouteObject } from 'react-router-dom';

import { MonitoringPacketRoutes } from './$requestId/routes';

export const MonitoringRoutes: RouteObject[] = [
  {
    path: 'monitoring',
    lazy: async () => {
      const { MonitoringPage } = await import('./page');
      return { element: <MonitoringPage /> };
    },
  },
  MonitoringPacketRoutes,
];
