import { Navigate, RouteObject } from 'react-router-dom';

import ProtectedRoute from '@/components/auth/protected-route';

import { UserType } from '@/lib/user/type';

import { ProfileRoutes } from './profile/routes';

export const ProviderRoutes: RouteObject[] = [
  {
    path: '/provider',
    lazy: async () => {
      const { ProviderLayout } = await import('./layout');
      return {
        element: (
          <ProtectedRoute userType={UserType.Provider}>
            <ProviderLayout />
          </ProtectedRoute>
        ),
      };
    },
    children: [
      {
        index: true,
        element: <Navigate to="tasks" replace />,
      },
      {
        path: 'tasks',
        lazy: async () => {
          const { TasksPage } = await import('./tasks/page');
          return { element: <TasksPage /> };
        },
      },
      ProfileRoutes,
      {
        path: 'settings',
        lazy: async () => {
          const { SettingsPage } = await import('./settings/page');
          return { element: <SettingsPage /> };
        },
      },
    ],
  },
];
