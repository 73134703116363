import { Navigate, RouteObject } from 'react-router-dom';

export const PracticeLocationByIdRoutes: RouteObject = {
  path: 'tax-entities/practice-locations/:practiceLocationId',
  lazy: async () => {
    const { TaxEntityPracticeLocationLayout } = await import('./layout');
    return { element: <TaxEntityPracticeLocationLayout /> };
  },
  children: [
    {
      index: true,
      element: <Navigate to="details" replace />,
    },
    {
      path: 'details',
      lazy: async () => {
        const { PracticeLocationDetailsLayout } = await import(
          './details/layout'
        );
        return { element: <PracticeLocationDetailsLayout /> };
      },
      children: [
        {
          index: true,
          element: <Navigate to="summary" replace />,
        },
        {
          path: 'summary',
          lazy: async () => {
            const { PracticeLocationSummaryPage } = await import(
              './details/summary/page'
            );
            return { element: <PracticeLocationSummaryPage /> };
          },
        },
        {
          path: 'practice-location',
          lazy: async () => {
            const { PracticeLocationPage } = await import(
              './details/practice-location/page'
            );
            return { element: <PracticeLocationPage /> };
          },
        },
      ],
    },
    {
      path: 'providers',
      lazy: async () => {
        const { PracticeLocationProvidersPage } = await import(
          './providers/page'
        );
        return { element: <PracticeLocationProvidersPage /> };
      },
    },
  ],
};
