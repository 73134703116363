import './instrument';

import { Suspense } from 'react';
import { StyleProvider } from '@ant-design/cssinjs';
import { App as AntdApp, ConfigProvider } from 'antd';
import { RouterProvider } from 'react-router-dom';

import { router } from '@/pages/routes';

import { QueryProvider } from '@/lib/api/query-client';

import FullPageLoading from './components/loading/full-page-loading';

export default function AssuredApp() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00CF98',
          colorPrimaryBg: '#E6FFF4',
          colorPrimaryBgHover: '#A3FFDA',
          colorPrimaryBorder: '#76F5C6',
          colorPrimaryBorderHover: '#4AE8B3',
          colorPrimaryHover: '#23DBA4',
          colorPrimaryActive: '#00A881',
          colorPrimaryTextHover: '#23DBA4',
          colorPrimaryText: '#00CF98',
          colorPrimaryTextActive: '#00A881',
          fontFamily: 'Nunito, sans-serif',

          // Customizing link styles
          colorLink: '#00CF98', // Default color for <a> tags
          colorLinkHover: '#4ae8b3',
        },
        components: {
          Progress: {
            defaultColor: '#00CF98',
          },
          Tabs: {
            colorBorderSecondary: '#d9d9d9',
          },
        },
      }}
    >
      <StyleProvider layer>
        <AntdApp>
          <QueryProvider>
            <Suspense fallback={<FullPageLoading />}>
              <RouterProvider router={router} />
            </Suspense>
          </QueryProvider>
        </AntdApp>
      </StyleProvider>
    </ConfigProvider>
  );
}
