export enum UserType {
  Admin = 'admin',
  Provider = 'provider',
  OpsAnalyst = 'ops-analyst',
}

// TODO: Replace this with the schema type once it is available
type DtoType = 'client_admin' | 'internal' | 'provider' | 'superadmin';

type Properties = {
  title: string;
  dtoModel: DtoType;
};

export const userTypeConfig: Record<UserType, Properties> = {
  [UserType.Admin]: {
    title: 'Admin',
    dtoModel: 'client_admin',
  },
  [UserType.Provider]: {
    title: 'Provider',
    dtoModel: 'provider',
  },
  [UserType.OpsAnalyst]: {
    title: 'Operations Analyst',
    dtoModel: 'internal',
  },
};

export function dtoToUserType(dto: string | null | undefined): UserType | null {
  switch (dto) {
    case 'client_admin':
      return UserType.Admin;

    case 'provider':
      return UserType.Provider;

    case 'internal':
      return UserType.OpsAnalyst;

    case 'superadmin': // superadmin is currently not supported in the frontend
      return null;
  }

  return null;
}
