import { ReactNode, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

import FullPageLoading from '@/components/loading/full-page-loading';

import { fetchClient } from '@/lib/api/client';
import { isHttpError } from '@/lib/api/http-error';
import { queryClient } from '@/lib/api/query-client';
import { JwtToken, useSessionStore } from '@/lib/auth/session-store';
import { getAccessTokenValidity } from '@/lib/auth/utils';
import { UserType } from '@/lib/user/type';

async function isAccessExpiredOnServer(accessToken: string): Promise<boolean> {
  try {
    await fetchClient.POST('/api/v1/users/token/verify/', {
      body: {
        token: accessToken,
      },
    });
    return false;
  } catch (error) {
    console.error('Failed to verify current access token', error);
    return true;
  }
}

async function refreshAccess(refreshToken: string): Promise<JwtToken | null> {
  const res = await fetchClient.POST('/api/v1/users/token/refresh/', {
    // @ts-expect-error - Schema error - Ignore requirement for `access` field
    body: {
      refresh: refreshToken,
    },
  });

  const accessToken = res.data?.access;
  if (!accessToken) {
    return null;
  }

  const jwtPayload = jwtDecode(accessToken);
  if (!jwtPayload) {
    return null;
  }

  return {
    token: accessToken,
    expiration: getAccessTokenValidity(jwtPayload),
    issuedAt: jwtPayload.iat ?? Date.now() / 1000,
  };
}

async function checkAuthentication(
  session: {
    accessToken: JwtToken;
    refreshToken: JwtToken;
  } | null,
  updateAccessToken: (value: JwtToken) => void,
): Promise<{
  valid: boolean;
  reason: string;
}> {
  if (!session) {
    return { valid: false, reason: 'NO_SESSION' };
  }

  const sessionExp = session.refreshToken.expiration;
  if (Date.now() / 1000 > sessionExp) {
    return { valid: false, reason: 'EXPIRED_SESSION' };
  }

  const accessToken = session.accessToken.token;
  const isAccExpiredRemote = await isAccessExpiredOnServer(accessToken);
  const isAccExpiredLocal = Date.now() / 1000 > session.accessToken.expiration;

  // If the access token is not valid on the server, or if it is close to expiring, refresh it
  const shouldRefresh = isAccExpiredRemote || isAccExpiredLocal;
  if (!shouldRefresh) {
    return { valid: true, reason: 'VALID_SESSION' };
  }

  const refreshToken = session.refreshToken.token;

  const newAccessToken = await refreshAccess(refreshToken);
  if (!newAccessToken) {
    return { valid: false, reason: 'REFRESH_ACCESS_TOKEN_FAILED' };
  }

  updateAccessToken(newAccessToken);

  return { valid: true, reason: 'VALID_SESSION' };
}

const sessionQueryKey = 'session-store';

/**
 * ProtectedRoute is a component that controls access to specific routes based
 * on user authentication and user type.
 *
 * This component wraps around route content and performs the following checks:
 * 1. Verifies if the user is authenticated and redirects to the login page if not
 * 2. Ensures the user has the correct user type for accessing the route else redirects to the not-found page
 *
 * @param {ReactNode} props.children - The child components to render if all checks pass
 * @param {UserType} props.userType - The required user type for accessing this route
 *
 * @example
 * <ProtectedRoute userType={UserType.Provider}>
 *   <ProviderDashboard />
 * </ProtectedRoute>
 */
export default function ProtectedRoute({
  children,
  userType,
}: {
  children: ReactNode;
  userType: UserType;
}) {
  const navigate = useNavigate();
  const user = useSessionStore((state) => state.user);
  const session = useSessionStore((state) => state.session);
  const updateAccessToken = useSessionStore((state) => state.updateAccessToken);
  const reset = useSessionStore((state) => state.reset);

  const {
    data: authStatus,
    isLoading,
    error,
  } = useQuery({
    queryKey: [sessionQueryKey, session],
    queryFn: () => checkAuthentication(session, updateAccessToken),
    refetchInterval: 15_000,
  });

  useEffect(() => {
    function logOut() {
      reset();
      queryClient.resetQueries({
        queryKey: [sessionQueryKey],
      });
      navigate('/sign-in');
    }

    if (authStatus?.valid === false) {
      logOut();
    }

    // Don't navigate to sign-in for unknown errors or no error
    if (
      isHttpError(error) &&
      (error.response.statusCode == 403 || error.response.statusCode == 401)
    ) {
      logOut();
    }
  }, [authStatus, error, reset, navigate]);

  useEffect(() => {
    const isUserTypeAllowed = user?.type === userType;
    if (!isUserTypeAllowed && authStatus?.valid === true) {
      navigate('/not-found', { replace: true });
    }
  }, [user, userType, navigate, authStatus?.valid]);

  if (isLoading) {
    return <FullPageLoading />;
  }

  return children;
}
