import { RouteObject } from 'react-router-dom';

import { CredentialingPacketRoutes } from './$requestId/routes';

export const CredentialingRoutes: RouteObject[] = [
  {
    path: 'credentialing',
    lazy: async () => {
      const { AdminCredentialingPage } = await import(
        '@/pages/admin/credentialing/page'
      );
      return { element: <AdminCredentialingPage /> };
    },
  },
  CredentialingPacketRoutes,
];
