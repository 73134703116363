import { useState } from 'react';
import { Avatar, Button, Popover, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useSessionStore } from '@/lib/auth/session-store';
import { useGetUserDetails } from '@/lib/user/query';
import { dtoToUserType } from '@/lib/user/type';

function nameInitials(
  firstName?: string | null,
  lastName?: string | null,
): string {
  if (firstName && lastName) {
    return firstName.charAt(0) + lastName.charAt(0);
  }

  if (firstName) {
    return firstName.charAt(0);
  }

  if (lastName) {
    return lastName.charAt(0);
  }
  return 'U';
}

export default function LayoutUserAvatar() {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const reset = useSessionStore((state) => state.reset);
  const userId = useSessionStore((state) => state.user?.id);

  const { data } = useGetUserDetails({
    userId: userId,
  });

  const logOutUser = () => {
    reset();
    navigate('/sign-in');
  };

  return (
    <>
      <Popover
        placement="bottomRight"
        content={
          <div className="flex w-[343px] flex-col gap-4">
            <div className="flex flex-col gap-2">
              <div className="w-full border-t border-borderColor" />
              {data && (
                <Typography.Text>
                  {data.first_name} {data.last_name} -{' '}
                  {dtoToUserType(data.user_type)}
                </Typography.Text>
              )}
            </div>
            <div className="flex justify-end">
              <Button key="submit" onClick={logOutUser}>
                Logout
              </Button>
            </div>
          </div>
        }
        title={data?.email ?? ''}
        trigger="click"
        arrow={false}
        open={modalOpen}
        onOpenChange={(open) => setModalOpen(open)}
      >
        <Avatar
          shape="circle"
          size="large"
          onClick={() => setModalOpen(true)}
          className="cursor-pointer"
        >
          {nameInitials(data?.first_name, data?.last_name)}
        </Avatar>
      </Popover>
    </>
  );
}
