import { ReactNode } from 'react';
import { Layout } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import { Link } from 'react-router-dom';

import { cn } from '@/lib/tailwind-util';

import LayoutUserAvatar from './user/avatar';

export default function CommonLayout({
  children,
  disableHomeIcon = false,
  isOnboarding = false,
}: {
  children: ReactNode;
  disableHomeIcon?: boolean;
  isOnboarding?: boolean;
}) {
  return (
    <Layout className="min-h-screen bg-backgroundGradient">
      <Header
        className={cn(
          'border-b border-borderColor bg-transparent p-0',
          isOnboarding ? 'h-auto bg-white' : '',
        )}
      >
        <div className={'flex items-center justify-between p-2'}>
          <Link
            className={!disableHomeIcon ? 'pointer-events-none' : ''}
            to="/"
          >
            <img src="/assured-logo.svg" alt="Logo" />
          </Link>
          <LayoutUserAvatar />
        </div>
      </Header>
      <Content className="bg-transparent">{children}</Content>
    </Layout>
  );
}
