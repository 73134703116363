import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { UserType } from '../user/type';

export interface JwtToken {
  token: string;
  expiration: number;
  issuedAt: number;
}

export interface SessionState {
  user: {
    id: string;
    type: UserType;
  } | null;
  session: {
    accessToken: JwtToken;
    refreshToken: JwtToken;
  } | null;
  client: {
    id: string;
    /**
     * @deprecated This field is deprecated and may be removed in future versions.
     */
    taxEntityExists: boolean;
  } | null;
  provider: {
    id: string;
    minCompleted: boolean;
  } | null;
}

type Actions = {
  updateUser: (value: SessionState['user']) => void;
  updateAccessToken: (value: JwtToken) => void;
  updateRefreshToken: (value: JwtToken) => void;
  updateProviderMinCompleted: (value: boolean) => void;
  updateClient: (
    value: {
      id: string;
      taxEntityExists: boolean;
    } | null,
  ) => void;
  updateProvider: (
    value: {
      id: string;
      minCompleted: boolean;
    } | null,
  ) => void;
  updateAll: (value: SessionState) => void;
  reset: () => void;
};

export const useSessionStore = create<SessionState & Actions>()(
  persist(
    immer((set) => ({
      user: null,
      session: null,
      provider: null,
      client: null,
      updateUser: (value) => {
        set((state) => {
          state.user = value;
        });
      },
      updateAccessToken: (value) => {
        set((state) => {
          if (state.session) {
            state.session.accessToken = value;
          }
        });
      },
      updateRefreshToken: (value) => {
        set((state) => {
          if (state.session) {
            state.session.refreshToken = value;
          }
        });
      },
      updateProviderMinCompleted: (value) => {
        set((state) => {
          if (state.provider) {
            state.provider.minCompleted = value;
          }
        });
      },
      updateClient: (value) => {
        set((state) => {
          state.client = value;
        });
      },
      updateProvider: (value) => {
        set((state) => {
          state.provider = value;
        });
      },
      updateAll: (value: SessionState) => {
        set((state) => {
          state.user = value.user;
          state.session = value.session;
          state.provider = value.provider;
          state.client = value.client;
        });
      },
      reset: () => {
        set(() => ({
          user: null,
          session: null,
          client: null,
          provider: null,
        }));
      },
    })),
    {
      name: 'session-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
