import { Navigate, RouteObject } from 'react-router-dom';

import ProtectedRoute from '@/components/auth/protected-route';

import { UserType } from '@/lib/user/type';

import { CredentialingRoutes } from './credentialing/routes';
import { AdminProvidersRoutes } from './providers/routes';
import { TaxEntitiesRoutes } from './tax-entities/routes';

export const AdminRoutes: RouteObject[] = [
  {
    path: '/admin',
    lazy: async () => {
      const { AdminLayout } = await import('./layout');
      return {
        element: (
          <ProtectedRoute userType={UserType.Admin}>
            <AdminLayout />
          </ProtectedRoute>
        ),
      };
    },
    children: [
      {
        index: true,
        element: <Navigate to="/admin/tasks" replace />,
      },
      {
        path: 'tasks',
        lazy: async () => {
          const { AdminTaskLayout } = await import(
            '@/pages/admin/tasks/layout'
          );
          return { element: <AdminTaskLayout /> };
        },
        children: [
          {
            index: true,
            element: <Navigate to="admin" replace />,
          },
          {
            path: 'admin',
            lazy: async () => {
              const { AdminTasksForAdminPage } = await import(
                '@/pages/admin/tasks/admin/page'
              );
              return { element: <AdminTasksForAdminPage /> };
            },
          },
          {
            path: 'provider',
            lazy: async () => {
              const { ProviderTasksForAdminPage } = await import(
                '@/pages/admin/tasks/provider/page'
              );
              return { element: <ProviderTasksForAdminPage /> };
            },
          },
          {
            path: 'monitoring',
            lazy: async () => {
              const { AdminMonitoringConcernsPage } = await import(
                '@/pages/admin/tasks/monitoring/page'
              );
              return { element: <AdminMonitoringConcernsPage /> };
            },
          },
        ],
      },
      {
        path: 'expirables',
        lazy: async () => {
          const { AdminExpirablesPage } = await import(
            '@/pages/admin/expirables/page'
          );
          return { element: <AdminExpirablesPage /> };
        },
      },
      AdminProvidersRoutes,
      ...TaxEntitiesRoutes,
      ...CredentialingRoutes,
      {
        path: 'users',
        lazy: async () => {
          const { UsersPage } = await import('@/pages/admin/users/page');
          return { element: <UsersPage /> };
        },
      },
      {
        path: 'settings',
        lazy: async () => {
          const { SettingsPage } = await import('@/pages/admin/settings/page');
          return { element: <SettingsPage /> };
        },
      },
    ],
  },
];
